import React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import {buildBody, getUniqueKey, isEmpty, showLoader, workWithLocalStorage} from '../../auxiliares/funciones';

import Busquedas from '../../paginas/busquedas';
import CONFIG from "../../CONFIG.json";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;
// const APP_HEADER = window.innerHeight - (49+92) - 25; // 25 de margen de abajo

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  // overflow: 'hidden',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

function TabPanel(props) {
  // eslint-disable-next-line react/prop-types
  const { children, value, index } = props;
  const visible = value !== index ? 'none' : 'block';

  return (
    <Box sx={{ display: visible }} id={`tabpanel-${index}`}>
      <Box component="div" sx={{ p: 3, overflowY: 'auto', maxHeight: props.APP_HEADER, overflowX: 'auto', maxWidth: props.CONTENT_WIDTH }}>
        {children}
      </Box>
    </Box>
  );
}

function showTab(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  // const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [tabs, addTabs] = useState([{ nombre: 'Busquedas', componente: Busquedas, params: {}, visible: true, index: 0 }])
  const [APP_HEADER,setAppHeader] = useState(window.innerHeight - (49+92) - 25);
  const [CONTENT_WIDTH,setContentWidth] = useState(window.innerWidth - 311);// 48 padding 32 padding tabs 279 side
  const loader = true;

  React.useEffect(() => {
    const checkLogin = async () => {
      const usuario = workWithLocalStorage('get','usuario',null);
      setLoader(true);
      const bodyJson = JSON.stringify(buildBody('', {token: usuario.token}))
      fetch(`${CONFIG.api}checklogin`,{
                method: 'POST',
                body: bodyJson,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
          if (isEmpty(result.data)) {
             workWithLocalStorage('remove','usuario',null);
             // eslint-disable-next-line spaced-comment
             window.location.reload();
          } else{
            // eslint-disable-next-line react/prop-types
            setLoader(false);
          }
      })
    };
    window.addEventListener("resize", () => {
      setAppHeader(window.innerHeight - (49+92) - 25);
      setContentWidth(window.innerWidth - 311);
    });
    checkLogin()
    return () => {
        window.removeEventListener("resize", () => {
          setAppHeader(window.innerHeight - (49+92) - 25);
          setContentWidth(window.innerWidth - 311);
        })
    }
}, []);

    const setLoader = (mostrar) =>{
      showLoader(mostrar)
    }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setTabs = (item) => {
    const index = tabs.findIndex((e) => e.nombre === item.nombre )
    if(index<0) {
      setLoader(true);
      item.index = getUniqueKey();
      addTabs(tabs.concat([item]));
      setValue(tabs.length);
    }else{
      setValue(index);
      showTab(index);
    }
  }

  const closeTab = (e, index) => {
    setLoader(true);
    tabs.splice(index,1);
    // tabs.find(e => e.index === index).visible = false
    addTabs(tabs)
    setValue(index-1)
    setLoader(false);
    // showTab(0)
    // document.getElementById(`tabpanel-${index}`).remove();
  }

      return (
        <RootStyle>
          <Backdrop id="loader"
            sx={{ color: '#103996', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loader}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
          <DashboardSidebar setTabs={setTabs} isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
          <MainStyle>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} scrollButtons allowScrollButtonsMobile variant="scrollable">
                {
                  // eslint-disable-next-line no-unused-expressions, array-callback-return
                  tabs.map((t,index) => <Tab sx={{ display: 'block' }} key={`${t.index}`} {...showTab(index)} label={
                      <>
                        <span>{t.nombre}{' '}</span>
                        <CloseIcon fontSize="inherit" onClick={(e) => closeTab(e,index) }/>
                      </>} />)
                }
                </Tabs>
              </Box>          
                {
                  // eslint-disable-next-line no-unused-expressions, array-callback-return
                  tabs.map((t,index) => <TabPanel key={`${t.index}`} value={value} index={index} APP_HEADER={APP_HEADER} CONTENT_WIDTH={CONTENT_WIDTH}>
                      <t.componente key={t.index} index={index} showLoader={setLoader} setTabs={setTabs} params={t.params} />
                    </TabPanel>)
                }
            </Box>
          </MainStyle>
        </RootStyle>
      );
}
